import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { InputAdornment } from '@mui/material';
import Composition from 'components/template-designer/types/composition.type';
import Checkbox from 'components/ui-components-v2/Checkbox';
import Page from 'components/template-designer/types/page.type';
import { AdobeLayer } from 'components/template-designer/types/layer.type';
import Folder from 'components/template-designer/types/folder.type';
import { TemplateData } from 'components/template-designer/types/template.type';
import TextField from 'components/ui-components-v2/TextField';
import Translation from 'components/data/Translation';
import IconButton from 'components/ui-components-v2/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import FilterListFolder from './components/filter-list-folder';
import FilterListItem from './components/filter-list-item';
import './styles/main.scss';

interface Props {
    className?: string;
    items: Composition[] | Page[] | undefined;
    layers: {
        [key: string]: AdobeLayer[];
    };
    selectedList: Composition[] | Page[];
    handleSelectItem: (item: (Composition | Page)[]) => void;
    onClickDeleteItem?: (item: Composition | Page) => void;
    unit: string;
    treeStructure?: (Composition | Folder)[] | undefined;
    showFolderCheckbox?: boolean;
    templateType: TemplateData['type'];
}

/**
 * @param className - Classname for styling.
 * @param items - Items to be shown in the sidebar.
 * @param layers - Layers from parseAfterEffectsData function.
 * @param selectedList - Selected list in the sidebar.
 * @param handleSelectItem - Function to fire when clicking an item.
 * @param onClickDeleteItem - Function to fire when deleting an item (custom format).
 * @param unit - The unit used in the project.
 * @param treeStructure - The tree structure of the items.
 * @param showFolderCheckbox - Show checkbox for the folder.
 * @param templateType - The type of the template.
 * @returns Filter list to filter on the passed items.
 */
const FilterList = ({
    className,
    items,
    layers,
    selectedList,
    handleSelectItem,
    onClickDeleteItem,
    unit,
    treeStructure,
    showFolderCheckbox = true,
    templateType
}: Props) => {
    const [searchTerm, setSearchTerm] = useState<string>('');

    /**
     * Filter items based on search term.
     */
    const newItems = useMemo(() => {
        if (searchTerm === '') return items;
        return items?.filter((item) => item.title.toLowerCase().includes(searchTerm.toLowerCase()));
    }, [items, searchTerm]);

    /**
     * Check if all items are selected.
     */
    const allSelected = (() => {
        if (searchTerm === '') {
            return selectedList.length === items?.length;
        }

        return selectedList.length === newItems?.length;
    })();

    const hideSelectAll = useMemo(() => {
        if (searchTerm === '' && newItems?.length === selectedList.length) return true;
        return newItems?.filter((item) => !selectedList.includes(item)).length === 0;
    }, [searchTerm, selectedList, newItems]);

    return (
        <div className={classNames('template-designer__filter-list', className)}>
            <div className="template-designer__filter-list__search-container">
                <TextField
                    className="template-designer__filter-list__search"
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    placeholder={(() => {
                        if (templateType === 'dynamicAfterEffects') {
                            return `${Translation.get('labels.search', 'common')} ${Translation.get('general.labels.composition_other', 'template-designer').toLowerCase()}`;
                        }

                        return `${Translation.get('labels.search', 'common')} ${Translation.get('general.labels.page_other', 'template-designer').toLowerCase()}`;
                    })()}
                    size="small"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" className="template-designer__filter-list__search__adornment-start">
                                <Icon fontSize="small">search</Icon>
                            </InputAdornment>
                        ),
                        endAdornment: searchTerm ? (
                            <InputAdornment position="end" className="template-designer__filter-list__search__adornment-end">
                                <IconButton onClick={() => setSearchTerm('')} size="small">
                                    <Icon fontSize="small">close</Icon>
                                </IconButton>
                            </InputAdornment>
                        ) : null
                    }}
                />

                <div className="template-designer__filter-list__selection">
                    {selectedList.length > 0 && (
                        <div className="template-designer__filter-list__selected">
                            <Checkbox
                                id="select-all-comps"
                                className="template-designer__filter-list__selection__checkbox"
                                size="small"
                                color="primary"
                                checked={newItems?.length === selectedList.length}
                                indeterminate={newItems?.length !== selectedList.length}
                                onClick={() => handleSelectItem(selectedList)}
                            />
                            <label htmlFor="select-all-comps">
                                {selectedList.length}{' '}
                                {(() => {
                                    if (templateType === 'dynamicAfterEffects') {
                                        return selectedList.length === 1
                                            ? Translation.get('general.labels.comp', 'template-designer')
                                            : Translation.get('general.labels.comp_other', 'template-designer');
                                    }

                                    return selectedList.length === 1
                                        ? Translation.get('general.labels.page', 'template-designer')
                                        : Translation.get('general.labels.page_other', 'template-designer');
                                })()}{' '}
                                {Translation.get('labels.selected', 'common')}
                            </label>
                        </div>
                    )}

                    {!hideSelectAll && (
                        <div
                            className="template-designer__filter-list__select-all"
                            onClick={() => {
                                const itemsToSelect = allSelected ? selectedList : newItems?.filter((item) => !selectedList.includes(item)) ?? [];

                                handleSelectItem(itemsToSelect);
                            }}>
                            {Translation.get('labels.selectAll', 'common')}{' '}
                            {(() => {
                                const isDynamicAE = templateType === 'dynamicAfterEffects';
                                const isSingleItem = newItems?.length === 1;

                                if (isDynamicAE) {
                                    return isSingleItem
                                        ? Translation.get('general.labels.comp', 'template-designer').toLowerCase()
                                        : Translation.get('general.labels.comp_other', 'template-designer').toLowerCase();
                                }

                                return isSingleItem
                                    ? Translation.get('general.labels.page', 'template-designer').toLowerCase()
                                    : Translation.get('general.labels.page_other', 'template-designer').toLowerCase();
                            })()}
                        </div>
                    )}
                </div>
            </div>

            {(!treeStructure || searchTerm !== '') && (
                <>
                    {newItems?.map((item) => {
                        return (
                            <FilterListItem
                                key={item.key}
                                item={item}
                                totalLayers={layers[item.key].length}
                                selectedList={selectedList}
                                handleSelectItem={handleSelectItem}
                                onClickDeleteItem={onClickDeleteItem}
                                unit={unit}
                                onlyItems
                            />
                        );
                    })}
                </>
            )}

            {treeStructure &&
                searchTerm === '' &&
                treeStructure?.map((item) => {
                    return 'items' in item ? (
                        <FilterListFolder
                            key={item.key}
                            title={item.title}
                            layers={layers}
                            selectedList={selectedList}
                            handleSelectItem={handleSelectItem}
                            onClickDeleteItem={onClickDeleteItem}
                            unit={unit}
                            treeStructure={(item as Folder).items}
                            showFolderCheckbox={showFolderCheckbox}
                            templateType={templateType}
                        />
                    ) : (
                        <FilterListItem
                            key={item.key}
                            item={item as Composition}
                            totalLayers={layers[item.key].length}
                            selectedList={selectedList}
                            handleSelectItem={handleSelectItem}
                            onClickDeleteItem={onClickDeleteItem}
                            unit={unit}
                        />
                    );
                })}
        </div>
    );
};

export default FilterList;
