import { Attributes } from 'components/template-designer/types/attribute.type';

export const afterEffectsAttributes = {
    text: 'text',
    scale: 'scale',
    visibility: 'setActive',
    muteAudio: 'muteAudio',
    fadeAudio: 'fadeAudio',
    image: 'image',
    backgroundImage: 'image',
    audio: 'audio',
    rotation: 'rotation',
    color: 'fontColor',
    fontFamily: 'font',
    textAlign: 'textAlignment',
    fontSize: 'fontSize',
    backgroundColor: 'backgroundColor',
    borderColor: 'borderColor',
    borderWidth: 'borderWidth',
    opacity: 'opacity',
    fillToComposition: 'fillToComposition',
    fitToComposition: 'fitToComposition',
    file: 'misc'
};

/**
 * Get attribute used for After Effects.
 * @param attribute - Attribute from input.
 * @returns - Attribute used for After Effects render.
 */
const convertAfterEffectsAttribute = (attribute: Attributes) => afterEffectsAttributes[attribute] || attribute;

export default convertAfterEffectsAttribute;
