import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import ListItemButton from '@mui/material/ListItemButton';
import Icon from 'components/ui-components-v2/Icon';
import Composition from 'components/template-designer/types/composition.type';
import Page from 'components/template-designer/types/page.type';
import { AdobeLayer } from 'components/template-designer/types/layer.type';
import Folder from 'components/template-designer/types/folder.type';
import Checkbox from 'components/ui-components-v2/Checkbox';
import Translation from 'components/data/Translation';
import { TemplateData } from 'components/template-designer/types/template.type';
import FilterListItem from './filter-list-item';
import '../styles/filter-list-folder.scss';

interface Props {
    className?: string;
    title: string;
    layers: {
        [key: string]: AdobeLayer[];
    };
    selectedList: Composition[] | Page[];
    handleSelectItem: (item: (Composition | Page)[]) => void;
    onClickDeleteItem?: (item: Composition | Page) => void;
    unit: string;
    treeStructure: (Composition | Folder)[];
    showFolderCheckbox?: boolean;
    templateType: TemplateData['type'];
}

/**
 * @param className - Classname for styling.
 * @param layers - Layers from parseAfterEffectsData function.
 * @param selectedList - Selected list in the sidebar.
 * @param handleSelectItem - Function to fire when clicking an item.
 * @param onClickDeleteItem - Function to fire when deleting an item (custom format).
 * @param unit - The unit used in the project.
 * @param treeStructure - The tree structure of the items.
 * @param title - Title of the folder.
 * @param showFolderCheckbox - Show checkbox for the folder.
 * @param templateType - The type of the template.
 * @returns Filter list to filter on the passed items.
 */
const FilterListFolder = ({
    className,
    layers,
    selectedList,
    handleSelectItem,
    onClickDeleteItem,
    unit,
    treeStructure,
    title,
    showFolderCheckbox,
    templateType
}: Props) => {
    const [expanded, setExpanded] = useState(true);

    /**
     * Get flat items from the tree structure.
     * @param items - Items to get flat items from.
     * @returns Flat items.
     */
    const getFlatItems = (items: (Composition | Folder)[]): (Composition | Page)[] => {
        const flatItems: (Composition | Page)[] = [];

        function flatten(items: (Composition | Folder)[]) {
            for (const item of items) {
                if ('items' in item) {
                    flatten(item.items);
                } else {
                    flatItems.push(item);
                }
            }
        }

        flatten(items);
        return flatItems;
    };

    /**
     * Get the current selected list in the current folder.
     */
    const selectedListCurrentFolder = useMemo(() => {
        const flatItems = getFlatItems(treeStructure);
        return flatItems.filter((item) => selectedList.some((selectedItem) => selectedItem.key === item.key));
    }, [treeStructure, selectedList]);

    /**
     * Get the checked state of the folder.
     */
    const folderCheckedState = useMemo(() => {
        if (selectedListCurrentFolder.length === 0) return false;

        const allKeys = getFlatItems(treeStructure).map((item) => item.key);

        if (allKeys.length === 0) return false;
        if (allKeys.length === selectedListCurrentFolder.length) return true;
        return 'indeterminate';
    }, [treeStructure, selectedListCurrentFolder]);

    const empty = treeStructure.length === 0;

    return (
        <div
            className={classNames(
                'template-designer__filter-list-folder',
                {
                    ['template-designer__filter-list-folder--empty']: empty
                },
                className
            )}>
            <ListItemButton
                className="template-designer__filter-list-folder__option"
                onClick={() => setExpanded((prevState) => !prevState)}
                disableRipple={empty}>
                <div className="template-designer__filter-list-folder__option__controls">
                    {!empty && (
                        <>
                            <Icon className="template-designer__filter-list-folder__option__arrow">{expanded ? 'arrow_drop_down' : 'arrow_right'}</Icon>
                            {showFolderCheckbox && (
                                <Checkbox
                                    className="template-designer__filter-list-folder__checkbox"
                                    size="small"
                                    color="primary"
                                    checked={typeof folderCheckedState === 'boolean' ? folderCheckedState : false}
                                    indeterminate={folderCheckedState === 'indeterminate'}
                                    onClick={(event) => {
                                        event.stopPropagation();

                                        if (folderCheckedState === false) {
                                            const flatItems = getFlatItems(treeStructure);
                                            return handleSelectItem(flatItems);
                                        }

                                        return handleSelectItem(selectedListCurrentFolder);
                                    }}
                                />
                            )}
                        </>
                    )}
                </div>
                <Icon className="template-designer__filter-list-folder__folder-icon">folder</Icon>
                <div>
                    <div className="template-designer__filter-list-folder__title">{title}</div>
                    <div className="template-designer__filter-list-folder__info">
                        {treeStructure.length}{' '}
                        {(() => {
                            if (templateType === 'dynamicAfterEffects') {
                                return treeStructure.length === 1
                                    ? Translation.get(`general.labels.composition`, 'template-designer').toLowerCase()
                                    : Translation.get(`general.labels.composition_other`, 'template-designer').toLowerCase();
                            }

                            return treeStructure.length === 1
                                ? Translation.get(`general.labels.page`, 'template-designer').toLowerCase()
                                : Translation.get(`general.labels.page_other`, 'template-designer').toLowerCase();
                        })()}
                    </div>
                </div>
            </ListItemButton>
            {expanded && !empty && (
                <div className="template-designer__filter-list-folder__option__options">
                    {treeStructure?.length > 0 &&
                        treeStructure.map((item) => {
                            return 'items' in item ? (
                                <div
                                    key={item.key}
                                    className={classNames('template-designer__filter-list-folder__option__options__folder', {
                                        ['template-designer__filter-list-folder__option__options__folder--small']: !showFolderCheckbox
                                    })}>
                                    <FilterListFolder
                                        title={item.title}
                                        layers={layers}
                                        selectedList={selectedList}
                                        handleSelectItem={handleSelectItem}
                                        onClickDeleteItem={onClickDeleteItem}
                                        unit={unit}
                                        treeStructure={(item as Folder).items}
                                        showFolderCheckbox={showFolderCheckbox}
                                        templateType={templateType}
                                    />
                                </div>
                            ) : (
                                <div
                                    key={item.key}
                                    className={classNames('template-designer__filter-list-folder__option__options__item', {
                                        ['template-designer__filter-list-folder__option__options__item--small']: !showFolderCheckbox
                                    })}>
                                    <FilterListItem
                                        item={item as Composition}
                                        totalLayers={layers[item.key].length}
                                        selectedList={selectedList}
                                        handleSelectItem={handleSelectItem}
                                        onClickDeleteItem={onClickDeleteItem}
                                        unit={unit}
                                    />
                                </div>
                            );
                        })}
                </div>
            )}
        </div>
    );
};

export default FilterListFolder;
