import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import { TemplateType } from 'types/templates.type';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import Attribute, { Attributes } from 'components/template-designer/types/attribute.type';
import Composition from 'components/template-designer/types/composition.type';
import { AdobeLayer } from 'components/template-designer/types/layer.type';
import Translation from 'components/data/Translation';
import Page from 'components/template-designer/types/page.type';
import LayerHelpers from 'components/template-designer/helpers/layer.helpers';
import Folder from 'components/template-designer/types/folder.type';
import TemplateDesignerStore from 'components/template-designer/data/template-designer-store';
import { DynamicLayerInput, FieldInput } from 'components/template-designer/types/dynamicLayer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { generateKey } from 'components/template-designer/utils/generateKey';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../../ui-components/dialog';
import SelectLayers from './select-layers';
import AttributeIcon from './attribute-icon';
import FilterList from './filter-list';
import convertAfterEffectsAttribute, { afterEffectsAttributes } from '../utils/convertAfterEffectsAttribute';
import convertInDesignAttribute, { inDesignAttributes } from '../utils/convertInDesignAttribute';
import { attributeInputs } from '../../dynamic-layers/components/dynamic-layer-edit/config/attribute-inputs';
import '../styles/connect-layer-dialog.scss';

interface Props {
    className?: string;
    onBack: () => void;
    onClose: () => void;
    templateType: TemplateType;
    view: 'dynamicLayer' | 'feed';
    layerType: AdobeLayer['type'];
    attribute: Attribute;
    items: Composition[] | Page[];
    layers: { [key: string]: AdobeLayer[] };
    dynamicLayers: DynamicLayerInput[];
    feedMapping: {
        // Layer key.
        [key: string]: {
            // Attribute.
            [key in Attributes]: string; // Attribute.
        };
    };
    unit: string;
    treeStructure?: (Composition | Folder)[] | undefined;
}

export interface Selection {
    layer: string;
    attribute: Attributes;
    item: string;
    name: string;
}

/**
 * @param className - Styling class.
 * @param onBack - Function to call when navigating back.
 * @param onClose - Function to call to close the dialog.
 * @param templateType - Type of the template.
 * @param view - In which view is the dialog opened.
 * @param layerType - Selected layer type to select layers from.
 * @param attribute - Selected attribute of layerType to select layers from.
 * @param items - Items from parse Adobe function.
 * @param layers - Layers from parse Adobe function.
 * @param dynamicLayers - Current dynamic layers.
 * @param feedMapping - Current feed mapping.
 * @param unit - Current unit.
 * @param treeStructure - The tree structure of the items.
 */
const ConnectLayerDialog = ({
    className,
    onBack,
    onClose,
    templateType,
    view,
    layerType,
    attribute,
    items,
    layers,
    dynamicLayers,
    feedMapping,
    unit,
    treeStructure
}: Props) => {
    const [selection, setSelection] = useState<Selection[]>([]);
    const [selectedItem, setSelectedItem] = useState<Composition | Page | null>(null);

    /**
     * Set or remove new item to the selected list.
     */
    const handleSelectItem = useCallback((items: (Composition | Page)[]) => {
        setSelectedItem((prevState) => {
            if (prevState?.key === items[0].key) {
                return null;
            }

            return items[0];
        });
    }, []);

    /**
     * Adds or removes the a new selection from the selection state.
     * @param items - Selected items.
     * @param layer - Current layer.
     */
    const handleSelection = useCallback(
        (selection: { items: Composition[] | Page[]; layer: AdobeLayer }[]) => {
            setSelection((prevState) => {
                let prevStateCopy = cloneDeep(prevState) as Selection[];

                selection.forEach(({ items, layer }) => {
                    const newSelection = items.map((item) => ({
                        item: item.key,
                        layer: (layer['originalName'] && layers[item.key].find((l) => l['originalName'] === layer['originalName'])?.key) || layer.key,
                        attribute: attribute.attribute,
                        name: layer.originalName || layer.title
                    }));
                    const layerKeys = newSelection.map((item) => item.layer);
                    const itemsKeys = newSelection.map((item) => item.item);

                    /**
                     * Finds if there is already an selection with the given composition and layer.
                     * Then in the next step, these can be removed from the state.
                     */
                    const foundSelection = prevStateCopy.reduce(
                        (all, selection) => {
                            const attributeExists = selection.attribute === attribute.attribute;
                            const layerExists = layerKeys.includes(selection.layer);
                            const itemExists = itemsKeys.includes(selection.item);

                            if (attributeExists && layerExists && itemExists) {
                                all.push({ layer: selection.layer, item: selection.item, attribute: attribute.attribute, name: selection.name });
                            }

                            return all;
                        },
                        [] as { layer: string; item: string; attribute: string; name: string }[]
                    );

                    /**
                     * If there is a found selection, then this needs to be removed from the state.
                     */
                    if (foundSelection.length) {
                        /**
                         * Remove the found selection from state.
                         * Use the found selection composition, layer and attribute to do this.
                         */
                        prevStateCopy = prevStateCopy.filter(
                            (item) =>
                                !foundSelection.some(
                                    (newItem) => newItem.item === item.item && newItem.layer === item.layer && newItem.attribute === item.attribute
                                )
                        );
                    } else {
                        prevStateCopy.push(...newSelection);
                    }
                });

                return prevStateCopy;
            });
        },
        [selection, attribute]
    );

    /**
     * Find every layer where the selected attribute can be changed.
     */
    const foundLayers = useMemo(() => {
        /**
         * Find all layers from the found layer types.
         */
        return Object.keys(layers).reduce(
            (all, item) => {
                if (!all[item]) {
                    all[item] = [];
                }

                layers[item].forEach((layer) => {
                    const foundLayer = all[item].find((currentLayer) => currentLayer.key === layer.key);
                    if (layerType === layer.type && !foundLayer) {
                        all[item].push(layer);
                    }
                });
                return all;
            },
            {} as { [key: string]: AdobeLayer[] }
        );
    }, [templateType, attribute, items, layers]);

    /**
     * Filter layers based on the found layers and which items are selected.
     */
    const allLayers = useMemo(() => {
        const filteredLayers = Object.keys(foundLayers).reduce(
            (all, item) => {
                if (selectedItem) {
                    if (selectedItem.key === item) {
                        all[item] = foundLayers[item];
                    } else {
                        all[item] = [];
                    }

                    return all;
                }

                all[item] = foundLayers[item];
                return all;
            },
            {} as { [key: string]: AdobeLayer[] }
        );

        /**
         * Covert layers to a single array.
         */
        return (
            Object.keys(filteredLayers)
                .reduce(
                    (all, item) => {
                        filteredLayers[item].forEach((layer) => {
                            all.push({ item, layer });
                        });
                        return all;
                    },
                    [] as { item: string; layer: AdobeLayer }[]
                )
                /**
                 * Filter out duplicates.
                 */
                .reduce(
                    (all, layer) => {
                        const foundLayer =
                            templateType === 'dynamicAfterEffects'
                                ? all.find(
                                      (item) =>
                                          item.layer.key === layer.layer.key ||
                                          (item.layer['originalName'] &&
                                              layer.layer['originalName'] &&
                                              item.layer['originalName'] === layer.layer['originalName'])
                                  )
                                : all.find(
                                      (item) =>
                                          item.layer.key === layer.layer.key ||
                                          (item.layer['title'] && layer.layer['title'] && item.layer['title'] === layer.layer['title'])
                                  );
                        if (!foundLayer) {
                            all.push(layer);
                        }
                        return all;
                    },
                    [] as { item: string; layer: AdobeLayer }[]
                )
        );
    }, [foundLayers, selectedItem]);

    /**
     * Create inputs from seledtion and save to the interface setup.
     */
    const handleAdd = useCallback(() => {
        switch (view) {
            case 'dynamicLayer':
                handleDynamicLayerSave();
                break;
            case 'feed':
                handleFeedMappingSave();
                break;
        }

        onClose();
    }, [view, selection, dynamicLayers, feedMapping]);

    /**
     * Create inputs from the selection and save them in the store.
     */
    const handleDynamicLayerSave = useCallback(() => {
        const newSelection = removeSelectionDuplicates(selection);

        const inputs = newSelection.reduce((all, { layer, attribute, item }) => {
            const foundLayer = LayerHelpers.findLayer(layer) as AdobeLayer;

            if (!foundLayer) return all;

            const data = attributeInputs[foundLayer.type]?.all[attribute];
            if (!data) return all;

            const containers =
                templateType === 'dynamicInDesign' ||
                data.attribute === 'text' ||
                data.attribute === 'image' ||
                data.attribute === 'video' ||
                data.attribute === 'audio'
                    ? Object.keys(layers).filter((key) => layers[key].some((layer) => layer.originalName === foundLayer.originalName))
                    : [item];

            const containerName = containers.map((container) => items.find((comp) => comp.key === container)?.title || '').join(', ');
            const label = DynamicLayerHelpers.generateFieldLabel(foundLayer.title, data.attribute as Attributes);

            const defaultSetting = (() => {
                if (typeof data.settings[data.defaultSetting] === 'function') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return (data.settings[data.defaultSetting] as (layer: Layer, attribute: Attributes) => any)(layer, attribute);
                }
                return data.settings[data.defaultSetting];
            })();

            const model = (() => {
                if (templateType === 'dynamicAfterEffects') {
                    return `${item}.${layer}.${convertAfterEffectsAttribute(data.attribute as Attributes)}`;
                }

                if (templateType === 'dynamicInDesign') {
                    return `${item}.${layer}.${convertInDesignAttribute(data.attribute as Attributes)}`;
                }

                return `${item}.${layer}`;
            })();

            const input: FieldInput = {
                key: generateKey('field'),
                type: 'field',
                label,
                attribute: data.attribute,
                frame: item,
                frameName: containerName,
                layerKey: foundLayer.key,
                settings: {
                    ...defaultSetting,
                    model
                },
                children: []
            };

            all.push(input);

            return all;
        }, [] as DynamicLayerInput[]);

        DynamicLayerHelpers.addInput(inputs);

        // TemplateDesignerStore.save(['dynamicLayers.main', [...cloneDeep(dynamicLayers), ...inputs]], { saveToHistory: false });
    }, [selection, dynamicLayers]);

    /**
     * Create mapped inputs from the selection and save them in the store.
     */
    const handleFeedMappingSave = useCallback(() => {
        let feedMappingCopied = cloneDeep(feedMapping);
        if (!feedMappingCopied || Array.isArray(feedMappingCopied)) {
            feedMappingCopied = {};
        }

        const newSelection = removeSelectionDuplicates(selection);

        newSelection.forEach(({ attribute, item, layer }) => {
            const newAttribute = (() => {
                if (templateType === 'dynamicAfterEffects') {
                    return convertAfterEffectsAttribute(attribute);
                }

                if (templateType === 'dynamicInDesign') {
                    return convertInDesignAttribute(attribute);
                }

                return attribute;
            })();

            feedMappingCopied[`${item}.${layer}`] = {
                ...feedMappingCopied[layer],
                [newAttribute]: `${layer}.${newAttribute}`
            };
        });

        TemplateDesignerStore.save(['dataVariables.main', feedMappingCopied], { saveToHistory: false });
    }, [selection, feedMapping]);

    /**
     * Remove duplicate selection if the attribute is text, image, video or audio.
     * Only a single input is needed for these attributes.
     * In the render, both layers will be filled in with same input value.
     * @param - Selection from the overview.
     */
    const removeSelectionDuplicates = useCallback(
        (selection: Selection[]) => {
            return selection.reduce((all, currentItem) => {
                if (
                    templateType === 'dynamicInDesign' ||
                    currentItem.attribute === 'text' ||
                    currentItem.attribute === 'image' ||
                    currentItem.attribute === 'video' ||
                    currentItem.attribute === 'audio'
                ) {
                    const alreadyAdded = all.find((item) => currentItem.attribute === item.attribute && currentItem.name === item.name);
                    if (alreadyAdded) {
                        return all;
                    }
                }

                all.push(currentItem);
                return all;
            }, [] as Selection[]);
        },
        [selection]
    );

    /**
     * Check how many layers are selected.
     */
    const totalLayers = useMemo(() => {
        return selection.reduce((all, item) => {
            const existingInput = all.find((layer) => layer === item.layer);

            if (!existingInput) {
                all.push(item.layer);
            }

            return all;
        }, [] as string[]).length;
    }, [selection]);

    /**
     * Check how many items are selected.
     */
    const totalItems = useMemo(() => {
        return selection.reduce(
            (all, item) => {
                const existingItem = all.find((currentItem) => currentItem === item.item);

                if (!existingItem) {
                    all.push(item.item);
                }

                return all;
            },
            [] as string[] | string[]
        ).length;
    }, [selection]);

    /**
     * Create an already selected list by finding getting the item, layer and attribute.
     * So they can't be added again.
     */
    const alreadySelected = useMemo((): Selection[] => {
        const selected: Selection[] = [];

        if (view === 'dynamicLayer') {
            const getInputs = (inputs: DynamicLayerInput[]) => {
                inputs.forEach((item) => {
                    if ('children' in item) {
                        getInputs(item.children);
                    }

                    if (item.type === 'group') {
                        return;
                    }

                    if ('layerKey' in item) {
                        const layer = LayerHelpers.findLayer(item.layerKey) as AdobeLayer;

                        selected.push({
                            item: item.frame || '',
                            layer: layer?.key || '',
                            attribute: item.attribute,
                            name: layer?.['originalName'] || layer?.title || ''
                        });
                    }
                });
            };

            getInputs(dynamicLayers);
        }

        if (view === 'feed') {
            Object.keys(feedMapping).forEach((key) => {
                const [frame, layer] = key.split('.');

                Object.keys(feedMapping[key]).forEach((attribute) => {
                    const newAttribute: Attributes = (() => {
                        /**
                         * Get the correct attributes based on the type.
                         */
                        const adobeAttributes = (() => {
                            if (templateType === 'dynamicAfterEffects') {
                                return afterEffectsAttributes;
                            }

                            if (templateType === 'dynamicInDesign') {
                                return inDesignAttributes;
                            }
                        })();

                        /**
                         * Found the original attribute with the Adobe attribute.
                         */
                        let foundAttribute = attribute;
                        for (const key in adobeAttributes) {
                            if (inDesignAttributes[key] === attribute) {
                                foundAttribute = key;
                            }
                        }

                        return foundAttribute as Attributes;
                    })();

                    selected.push({
                        item: frame,
                        layer,
                        attribute: newAttribute,
                        name: layer
                    });
                });
            });
        }

        return selected;
    }, [dynamicLayers]);

    return (
        <Dialog open clickAway={onClose} size="xl" className={classNames('template-designer__connect-layer-dialog', className)}>
            <DialogTitle onClose={onClose}>{Translation.get('adobe.general.connectLayerDialog.title', 'template-designer')}</DialogTitle>
            <div className="template-designer__connect-layer-dialog__action-bar">
                <IconButton onClick={onBack}>
                    <Icon>arrow_back</Icon>
                </IconButton>

                <AttributeIcon
                    className="template-designer__input-type-item__option__attribute-icon"
                    size="large"
                    icon={DynamicLayerHelpers.getDynamicLayerIcon(attribute.attribute)}
                    title={`${Translation.get('adobe.general.connectLayerDialog.change', 'template-designer')} ${attribute.attribute}`}
                />
            </div>
            <DialogContent className="template-designer__connect-layer-dialog__content">
                <FilterList
                    className="template-designer__connect-layer-dialog__content__items"
                    items={items}
                    selectedList={selectedItem ? [selectedItem] : []}
                    layers={foundLayers}
                    handleSelectItem={handleSelectItem}
                    unit={unit}
                    treeStructure={treeStructure}
                    showFolderCheckbox={false}
                    templateType={templateType}
                />
                <SelectLayers
                    className="template-designer__connect-layer-dialog__content__layers"
                    templateType={templateType}
                    items={items}
                    attribute={attribute}
                    layers={foundLayers}
                    allLayers={allLayers}
                    selectedItem={selectedItem}
                    selection={selection}
                    alreadySelected={alreadySelected}
                    handleSelection={handleSelection}
                />
            </DialogContent>
            <DialogActions className="template-designer__connect-layer-dialog__actions" alignRight>
                <div className="template-designer__connect-layer-dialog__actions__selected">
                    {Translation.get(`adobe.${templateType}.connectLayerDialog.selected`, 'template-designer', {
                        totalLayers,
                        totalItems
                    }).toLowerCase()}
                </div>
                <Button disabled={selection.length === 0} onClick={handleAdd} size="small" variant="contained" color="primary">
                    {Translation.get('dynamicLayers.dynamicLayerInput.title', 'template-designer', { count: selection.length })}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConnectLayerDialog;
