import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { TemplateType } from 'types/templates.type';
import Attribute from 'components/template-designer/types/attribute.type';
import Translation from 'components/data/Translation';
import Layer, { AdobeLayer } from 'components/template-designer/types/layer.type';
import Composition from 'components/template-designer/types/composition.type';
import Page from 'components/template-designer/types/page.type';
import Template from 'components/template-designer/types/template.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Dialog, { DialogContent, DialogTitle } from '../../ui-components/dialog';
import InputTypeItem from './input-type-item';
import ConnectLayerDialog from './connect-layer-dialog';
import getInputOptions from '../utils/getInputOptions';
import '../styles/input-type-dialog.scss';

interface Data {
    templateSetup: Template['templateSetup'];
    compositions: Template['compositions'];
    pages: Template['pages'];
    layers: Template['layers'];
    dynamicLayers: Template['dynamicLayers'];
    feedMapping: Template['dataVariables'];
}

interface Props {
    className?: string;
    onClose: () => void;
    templateType: TemplateType;
    view: 'dynamicLayer' | 'feed';
}

/**
 * @param className - Styling class.
 * @param onClose - Function to call to close the dialog.
 * @param templateType - Type of the template.
 * @param view - In which view is the dialog opened.
 */
const InputTypeDialog = ({ className, onClose, templateType, view }: Props) => {
    const { templateSetup, compositions, pages, layers, dynamicLayers, feedMapping } = useComponentStore<Data>('TemplateDesigner', {
        fields: {
            templateSetup: 'templateSetup',
            compositions: 'compositions',
            pages: 'pages',
            layers: 'layers',
            feedMapping: 'dataVariables',
            dynamicLayers: 'dynamicLayers'
        }
    });

    const items: Composition[] | Page[] = (() => {
        if (templateType === 'dynamicAfterEffects') {
            return compositions!;
        } else if (templateType === 'dynamicInDesign') {
            return pages!;
        }

        return [];
    })();

    const [selectedLayerType, setSelectedLayerType] = useState<Layer['type'] | null>();
    const [selectedAttribute, setSelectedAttribute] = useState<Attribute | null>();

    const inputOptions = useMemo(() => getInputOptions(templateType), [templateType]);

    /**
     * Handle click on attribute.
     * @param attribute - Selected attribute.
     * @param layerType - Selected layer type.
     */
    const handleClickAttribute = (attribute: Attribute, layerType: AdobeLayer['type']) => {
        setSelectedLayerType(layerType);
        setSelectedAttribute(attribute);
    };

    if (selectedLayerType && selectedAttribute) {
        return (
            <ConnectLayerDialog
                onBack={() => setSelectedAttribute(null)}
                onClose={onClose}
                templateType={templateType}
                view={view}
                layerType={selectedLayerType}
                attribute={selectedAttribute}
                items={items}
                layers={layers}
                dynamicLayers={dynamicLayers['main']}
                feedMapping={feedMapping['main']}
                unit={templateSetup.adobe?.unit ?? 'px'}
                treeStructure={templateSetup.adobe?.treeStructure}
            />
        );
    }

    return (
        <Dialog open clickAway={onClose} className={classNames('template-designer__input-type-dialog', className)}>
            <DialogTitle onClose={onClose}>{Translation.get('adobe.general.inputDialog.title', 'template-designer')}</DialogTitle>
            <DialogContent className="template-designer__input-type-dialog__content">
                {Object.keys(inputOptions).map((option) => (
                    <InputTypeItem
                        key={option}
                        className="template-designer__input-type-dialog__content__item"
                        title={inputOptions[option].title}
                        icon={inputOptions[option].icon}
                        layerType={inputOptions[option].layerType}
                        options={inputOptions[option].options}
                        onClickAttribute={handleClickAttribute}
                    />
                ))}
            </DialogContent>
        </Dialog>
    );
};

export default InputTypeDialog;
