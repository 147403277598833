import { Attributes } from 'components/template-designer/types/attribute.type';

export const inDesignAttributes = {
    text: 'text',
    visibility: 'visible',
    image: 'source',
    backgroundImage: 'source',
    backgroundColor: 'fillColor',
    color: 'textColor',
    fontFamily: 'font',
    borderColor: 'strokeColor',
    borderWidth: 'strokeWidth'
};

/**
 * Get attribute used for InDesign.
 * @param attribute - Attribute from input.
 * @returns - Attribute used for InDesign render.
 */
const convertInDesignAttribute = (attribute: Attributes) => inDesignAttributes[attribute] || attribute;

export default convertInDesignAttribute;
