import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import ListItemButton from '@mui/material/ListItemButton';
import { TemplateType } from 'types/templates.type';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Checkbox from 'components/ui-components-v2/Checkbox';
import LayerHelpers from 'components/template-designer/helpers/layer.helpers';
import { AdobeLayer, LayerTypes } from 'components/template-designer/types/layer.type';
import Composition from 'components/template-designer/types/composition.type';
import Attribute from 'components/template-designer/types/attribute.type';
import Page from 'components/template-designer/types/page.type';
import Translation from 'components/data/Translation';
import GenericIcon from 'components/ui-components/GenericIcon';
import { Selection } from './connect-layer-dialog';
import '../styles/select-layer.scss';

interface Props {
    className?: string;
    templateType: TemplateType;
    attribute: Attribute;
    layer: AdobeLayer;
    layerItems: Composition[] | Page[];
    selection: Selection[];
    alreadySelected: Selection[];
    handleSelection: (selection: { items: Composition[] | Page[]; layer: AdobeLayer }[]) => void;
}

/**
 * @param className - Classname for styling.
 * @param attribute - Attribute that is selected.
 * @param layer - Layer to be shown.
 * @param layerItems - In which items the layer is defined in.
 * @param selection - Current selection of all layers and items.
 * @param alreadySelected - Inputs that are already added to the interface setup.
 * @param handleSelection - Function to call for when selecting a layer.
 * @returns Layer row that can be expanded to show the items.
 */
const SelectLayer = ({ className, templateType, attribute, layer, layerItems, selection, alreadySelected, handleSelection }: Props) => {
    const [expanded, setExpanded] = useState(false);
    const correctLayerType = useMemo((): LayerTypes => layer.type.split('_')[1] as LayerTypes, [layer]);

    /**
     * Check if the passed item is in the selection or already selected.
     * @param item - Current item.
     * @param checkOnSelection - If the check also needs to check the selection.
     */
    const checkInSelection = useCallback(
        (item: Composition | Page, checkOnSelection: boolean): boolean => {
            const existsInSelection = !!selection.find(
                (sel) => sel.item === item.key && sel.attribute === attribute.attribute && (sel.layer === layer.key || sel.name === layer['originalName'])
            );

            const existsInAlreadySelected = !!alreadySelected.find(
                (sel) => sel.item === item.key && sel.attribute === attribute.attribute && (sel.layer === layer.key || sel.name === layer['originalName'])
            );

            if (checkOnSelection) {
                return existsInSelection || existsInAlreadySelected;
            }

            return existsInAlreadySelected;
        },
        [selection, attribute, layer, alreadySelected]
    );

    /**
     * Calculate if the parent layer needs be selected.
     * Checking by calling the checkInSelection function.
     * If it is an text, image, video or audio attribute, then not every item needs to exists.
     */
    const parentSelected = useMemo(() => {
        if (
            templateType === 'dynamicInDesign' ||
            attribute.attribute === 'text' ||
            attribute.attribute === 'image' ||
            attribute.attribute === 'video' ||
            attribute.attribute === 'audio'
        ) {
            return !!layerItems.some((item) => checkInSelection(item, true));
        }

        return !!layerItems.every((item) => checkInSelection(item, true));
    }, [attribute, layerItems]);

    /**
     * Calculate if a child is selected.
     * Checks by calling the checkInSelection function.
     * Is needed for the 'indeterminate' state for the checkbox.
     */
    const childrenSelected = useMemo(() => !!layerItems.some((item) => checkInSelection(item, true)), [layerItems]);

    /**
     * Calculate if the parent needs to be disabled.
     * Checks by calling the checkInSelection function.
     * If it is an text or image attribute, then not every item needs to exists.
     */
    const parentDisabled = useMemo(() => {
        if (
            templateType === 'dynamicInDesign' ||
            attribute.attribute === 'text' ||
            attribute.attribute === 'image' ||
            attribute.attribute === 'video' ||
            attribute.attribute === 'audio'
        ) {
            return !!layerItems.some((item) => checkInSelection(item, false));
        }

        return !!layerItems.every((item) => checkInSelection(item, false));
    }, [attribute, layerItems]);

    return (
        <div className={classNames('template-designer__select-layer', expanded && 'template-designer__select-layer--open', className)}>
            <ListItemButton className="template-designer__select-layer__option" onClick={() => setExpanded((prevState) => !prevState)}>
                <Icon className="template-designer__select-layer__option__arrow">{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>

                <Checkbox
                    className="template-designer__select-layer__option__checkbox"
                    size="small"
                    color="primary"
                    checked={parentSelected}
                    disabled={parentDisabled}
                    indeterminate={!parentSelected && childrenSelected}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleSelection([{ items: layerItems, layer }]);
                    }}
                />

                <GenericIcon className="template-designer__select-layer__option__icon" icon={LayerHelpers.getLayerIcon(layer.type)} />
                <div className="template-designer__select-layer__option__type">
                    {correctLayerType} {Translation.get('general.labels.layer', 'template-designer').toLowerCase()}
                </div>
                <div className="template-designer__select-layer__option__title">
                    <div>{layer.title}</div>
                    {!layer.hasLayerName && (
                        <Tooltip title={Translation.get('adobe.dynamicInDesign.connectLayerDialog.warningNoName', 'template-designer')}>
                            <Icon className="template-designer__select-layer__option__title__icon">warning</Icon>
                        </Tooltip>
                    )}
                </div>
                {templateType === 'dynamicAfterEffects' && (
                    <div className="template-designer__select-layer__option__items">
                        <Icon>landscape</Icon> {layerItems.length}{' '}
                        {Translation.get('general.labels.composition', 'template-designer', { count: layerItems.length })}
                    </div>
                )}
                {templateType === 'dynamicInDesign' && (
                    <div className="template-designer__select-layer__option__items">
                        <Icon>description</Icon> {layerItems.length} {Translation.get('general.labels.page', 'template-designer', { count: layerItems.length })}
                    </div>
                )}
            </ListItemButton>

            {expanded && (
                <div className="template-designer__select-layer__items">
                    {layerItems.map((layerItem) => {
                        /**
                         * If the parent is selected. Then the child is automatically selected as well.
                         * Otherwise, check the individually item.
                         */
                        const checked = (() => {
                            if (parentSelected) {
                                return true;
                            }

                            return checkInSelection(layerItem, true);
                        })();

                        /**
                         * If the parent is disabled. Then the child is automatically disabled as well.
                         * Otherwise, check the individually item.
                         */
                        const disabled = (() => {
                            if (parentDisabled) {
                                return true;
                            }

                            return checkInSelection(layerItem, false);
                        })();

                        return (
                            <ListItemButton
                                key={layerItem.key + '_' + layer.key}
                                className="template-designer__select-layer__item"
                                dense
                                disabled={disabled}
                                onClick={(e) => {
                                    e.stopPropagation();

                                    /**
                                     * If the attribute is of type text, image, video or audio, or template type is dynamicInDesign, then add all layer items to the selection.
                                     * Because there only can be a single text and image input.
                                     */
                                    if (
                                        templateType === 'dynamicInDesign' ||
                                        attribute.attribute === 'text' ||
                                        attribute.attribute === 'image' ||
                                        attribute.attribute === 'video' ||
                                        attribute.attribute === 'audio'
                                    ) {
                                        return handleSelection([{ items: [...layerItems], layer }]);
                                    }

                                    handleSelection([{ items: [layerItem], layer }]);
                                }}>
                                <Checkbox
                                    className="template-designer__select-layer__item__checkbox"
                                    size="small"
                                    color="primary"
                                    checked={checked}
                                    disabled={disabled}
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        /**
                                         * If the attribute is of type text, image, video or audio, or template type is dynamicInDesign, then add all layer items to the selection.
                                         * Because there only can be a single text and image input.
                                         */
                                        if (
                                            templateType === 'dynamicInDesign' ||
                                            attribute.attribute === 'text' ||
                                            attribute.attribute === 'image' ||
                                            attribute.attribute === 'video' ||
                                            attribute.attribute === 'audio'
                                        ) {
                                            return handleSelection([{ items: [...layerItems], layer }]);
                                        }

                                        handleSelection([{ items: [layerItem], layer }]);
                                    }}
                                />
                                {templateType === 'dynamicAfterEffects' && (
                                    <>
                                        <div className="template-designer__select-layer__item__icon">
                                            <Icon>landscape</Icon>
                                            {Translation.get('general.labels.composition', 'template-designer')}
                                        </div>
                                        <div className="template-designer__select-layer__item__title">{layerItem.title}</div>
                                    </>
                                )}
                                {templateType === 'dynamicInDesign' && (
                                    <>
                                        <div className="template-designer__select-layer__item__icon">
                                            <Icon>description</Icon>
                                            {Translation.get('general.labels.page', 'template-designer')}
                                        </div>
                                        <div className="template-designer__select-layer__item__title">{layerItem.title}</div>
                                    </>
                                )}
                            </ListItemButton>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default SelectLayer;
