import React from 'react';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import '../styles/attribute-icon.scss';

interface Props {
    className?: string;
    icon: string;
    title: string;
    size?: 'small' | 'medium' | 'large';
}

/**
 * @param className - Custom className for styling.
 * @param icon - Attribute icon.
 * @param title - Attribute title.
 * @param size - Size of the icon.
 * @returns Icon and title used by input and connect layer dialog.
 */
const AttributeIcon = ({ className, icon, title, size = 'medium' }: Props) => (
    <div className={classNames('template-designer__attribute-icon', className)}>
        <Icon
            baseClassName="material-icons material-icons-outlined"
            className={classNames('template-designer__attribute-icon__icon-container', `template-designer__attribute-icon__icon-container--${size}`)}>
            {icon}
        </Icon>
        <div className="template-designer__attribute-icon__title">{title}</div>
    </div>
);

export default AttributeIcon;
