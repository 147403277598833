import { TemplateType } from 'types/templates.type';
import Translation from 'components/data/Translation';
import Attribute from 'components/template-designer/types/attribute.type';
import { AdobeLayer } from 'components/template-designer/types/layer.type';
import { attributeInputs } from '../../dynamic-layers/components/dynamic-layer-edit/config/attribute-inputs';

/**
 * Get the correct input options based on the template type.
 * @param templateType - Type of the template.
 * @returns Object with title, icon and options.
 */
const getInputOptions = (
    templateType: TemplateType
): { [key: string]: { title: string; icon: string; layerType: AdobeLayer['type']; options: Attribute[] } } => {
    const options = {
        dynamicAfterEffects: {
            textOptions: {
                title: Translation.get('adobe.dynamicAfterEffects.inputDialog.textOptions', 'template-designer'),
                icon: 'title',
                layerType: 'AE_text',
                options: Object.values(attributeInputs.AE_text.all)
            },
            shapeOptions: {
                title: Translation.get('adobe.dynamicAfterEffects.inputDialog.shapeOptions', 'template-designer'),
                icon: 'rectangle',
                layerType: 'AE_shape',
                options: Object.values(attributeInputs.AE_shape.all)
            },
            imageOptions: {
                title: Translation.get('adobe.dynamicAfterEffects.inputDialog.imageOptions', 'template-designer'),
                icon: 'insert_photo',
                layerType: 'AE_image',
                options: Object.values(attributeInputs.AE_image.all)
            },
            videoOptions: {
                title: Translation.get('adobe.dynamicAfterEffects.inputDialog.videoOptions', 'template-designer'),
                icon: 'videocam',
                layerType: 'AE_video',
                options: Object.values(attributeInputs.AE_video.all)
            },
            audioOptions: {
                title: Translation.get('adobe.dynamicAfterEffects.inputDialog.audioOptions', 'template-designer'),
                icon: 'music_note',
                layerType: 'AE_audio',
                options: Object.values(attributeInputs.AE_audio.all)
            },
            otherOptions: {
                title: Translation.get('adobe.dynamicAfterEffects.inputDialog.otherOptions', 'template-designer'),
                icon: 'settings',
                layerType: 'AE_misc',
                options: Object.values(attributeInputs.AE_misc.all)
            }
        },
        dynamicInDesign: {
            textOptions: {
                title: Translation.get('adobe.dynamicInDesign.inputDialog.textOptions', 'template-designer'),
                icon: 'title',
                layerType: 'ID_TextFrame',
                options: Object.values(attributeInputs.ID_TextFrame.all)
            },
            rectangleOptions: {
                title: Translation.get('adobe.dynamicInDesign.inputDialog.rectangleOptions', 'template-designer'),
                icon: 'rectangle',
                layerType: 'ID_Rectangle',
                options: Object.values(attributeInputs.ID_Rectangle.all)
            },
            polygonOptions: {
                title: Translation.get('adobe.dynamicInDesign.inputDialog.polygonOptions', 'template-designer'),
                icon: 'circle',
                layerType: 'ID_Polygon',
                options: Object.values(attributeInputs.ID_Polygon.all)
            },
            imageOptions: {
                title: Translation.get('adobe.dynamicInDesign.inputDialog.imageOptions', 'template-designer'),
                icon: 'insert_photo',
                layerType: 'ID_Image',
                options: Object.values(attributeInputs.ID_Image.all)
            },
            layerOptions: {
                title: Translation.get('adobe.dynamicInDesign.inputDialog.layerOptions', 'template-designer'),
                icon: 'layers',
                layerType: 'ID_Layer',
                options: Object.values(attributeInputs.ID_Layer.all)
            },
            pdfOptions: {
                title: Translation.get('adobe.dynamicInDesign.inputDialog.pdfOptions', 'template-designer'),
                icon: 'picture_as_pdf',
                layerType: 'ID_PDF',
                options: Object.values(attributeInputs.ID_PDF.all)
            }
        }
    };

    return options[templateType];
};

export default getInputOptions;
