import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import ListItemButton from '@mui/material/ListItemButton';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import Attribute from 'components/template-designer/types/attribute.type';
import { AdobeLayer } from 'components/template-designer/types/layer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import AttributeIcon from './attribute-icon';
import '../styles/input-type-item.scss';

interface Props {
    className?: string;
    title: string;
    icon: string;
    layerType: AdobeLayer['type'];
    options: Attribute[];
    onClickAttribute: (attribute: Attribute, layerType: AdobeLayer['type']) => void;
}

/**
 * @param className - Styling class.
 * @param title - Title of the option.
 * @param icon - Icon of the option.
 * @param layerType - Layer type of the option.
 * @param options - Attribute options to be shown when item is expanded.
 * @param onClickAttribute - Function to call when clicking on a attribute.
 * @return Item with an expanded list of attributes.
 */
const InputTypeItem = ({ className, title, icon, layerType, options, onClickAttribute }: Props) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div className={classNames('template-designer__input-type-item', expanded && 'template-designer__input-type-item--open', className)}>
            <ListItemButton className="template-designer__input-type-item__option" onClick={() => setExpanded((prevState) => !prevState)}>
                <Icon>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                <AttributeIcon icon={icon} title={title} />
            </ListItemButton>

            {expanded && (
                <div className="template-designer__input-type-item__option__options">
                    {options &&
                        options.map((option) => (
                            <ListItemButton
                                key={option.attribute}
                                dense
                                className="template-designer__input-type-item__option__attribute"
                                onClick={() => onClickAttribute(option, layerType)}>
                                <AttributeIcon
                                    className="template-designer__input-type-item__option__attribute-icon"
                                    icon={DynamicLayerHelpers.getDynamicLayerIcon(option.attribute)}
                                    title={option.label}
                                />

                                <Button variant="text" color="primary" className="template-designer__input-type-item__option__attribute-button">
                                    {Translation.get('labels.choose', 'common')}
                                </Button>
                            </ListItemButton>
                        ))}
                </div>
            )}
        </div>
    );
};

export default InputTypeItem;
