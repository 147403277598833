import React, { useMemo } from 'react';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import { ListItemButton } from '@mui/material';
import { IconDelete } from 'components/template-designer/assets/icons';
import Composition from 'components/template-designer/types/composition.type';
import Page from 'components/template-designer/types/page.type';
import Translation from 'components/data/Translation';
import { PreviewIcon } from 'components/ui-components/PreviewIcon';
import TemplateDesignerStore from 'components/template-designer/data/template-designer-store';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import TextInlineEdit from 'components/ui-components/TextInlineEdit';
import Template, { TemplateData } from 'components/template-designer/types/template.type';
import Folder from 'components/template-designer/types/folder.type';
import Checkbox from 'components/ui-components-v2/Checkbox';
import '../styles/filter-list-item.scss';

interface Props {
    className?: string;
    item: Composition | Page;
    totalLayers: number;
    selectedList: Composition[] | Page[];
    handleSelectItem: (item: (Composition | Page)[]) => void;
    onClickDeleteItem?: (item: Composition | Page) => void;
    unit: string;
    onlyItems?: boolean;
}

/**
 * @param className - Custom className for styling.
 * @param item - Item to show.
 * @param selectedList - Selected list in the sidebar.
 * @param handleSelectItem - Function to call when a item is clicked.
 * @param onClickDeleteItem - Function to call when a item (custom format) is deleted.
 * @param unit - The unit used in the project.
 * @returns Filter list item with title, size and total layers.
 */
const FilterListItem = ({ className, item, totalLayers, selectedList, handleSelectItem, onClickDeleteItem, unit, onlyItems }: Props) => {
    const onSaveTitle = (newTitle: string) => {
        if (!newTitle) return;

        const templateType = getTemplateData<TemplateData['type']>('templateData.type');

        const updateTitle = (items: (Composition | Page | Folder)[], key: string, title: string) => {
            if (!items) return;
            for (const item of items) {
                if (item.key === key) {
                    item.title = title;
                    break;
                }
            }
            return items;
        };

        const updateTreeTitle = (items: (Composition | Page | Folder)[], key: string, title: string) => {
            for (const currentItem of items) {
                if ('items' in currentItem && Array.isArray(currentItem.items)) {
                    updateTreeTitle(currentItem.items as (Composition | Page | Folder)[], key, title);
                }

                if (currentItem.key === key) {
                    currentItem.title = title;
                    break;
                }
            }
        };

        if (templateType === 'dynamicAfterEffects') {
            const newCompositions = getTemplateData<Template['compositions']>('compositions');
            if (!newCompositions) return;

            const newTreeStructure = getTemplateData<Composition[] | Folder[]>('templateSetup.adobe.treeStructure');
            if (!newTreeStructure) return;

            const updatedCompositions = updateTitle(newCompositions, item.key, newTitle);
            updateTreeTitle(newTreeStructure, item.key, newTitle);

            TemplateDesignerStore.save([
                ['compositions', updatedCompositions],
                ['templateSetup.adobe.treeStructure', newTreeStructure]
            ]);
        } else if (templateType === 'dynamicInDesign') {
            const newPages = getTemplateData<Template['pages']>('pages');
            if (!newPages) return;

            const updatedPages = updateTitle(newPages, item.key, newTitle);

            TemplateDesignerStore.save([['pages', updatedPages]]);
        }
    };

    const selected = useMemo(() => !!selectedList.find((selectedItem) => selectedItem.key === item.key), [selectedList, item]);

    return (
        <ListItemButton className={classNames('template-designer__filter-list-item', className)} onClick={() => handleSelectItem([item])}>
            <div
                className={classNames('template-designer__filter-list-item__controls', {
                    ['template-designer__filter-list-item__controls--small']: onlyItems
                })}>
                <Checkbox className="template-designer__filter-list-item__checkbox" size="small" color="primary" checked={selected} />
            </div>
            <div className="template-designer__filter-list-item__preview-container">
                <PreviewIcon className="template-designer__filter-list-item__preview" width={item.width} height={item.height} maxSize={22} />
            </div>
            <div className="template-designer__filter-list-item__details">
                <TextInlineEdit
                    value={item.title || Translation.get('adobe.general.labels.noTitle', 'template-designer')}
                    onChange={onSaveTitle}
                    useDebounce
                    useDoubleClick
                    noEmptyAllowed
                    textFieldProps={{
                        InputProps: {
                            classes: { root: 'template-designer__filter-list-item__title__textfield' }
                        }
                    }}
                    valueProps={{ style: { fontWeight: 'bold', fontSize: 14 } }}
                />
                <div className="template-designer__filter-list-item__info">
                    {item.dimensions
                        ? `${Math.round(item.dimensions[unit].width)} x ${Math.round(item.dimensions[unit].height)} (${unit})`
                        : `${item.width} x ${item.height}`}{' '}
                    | {totalLayers} {Translation.get('general.labels.layer', 'template-designer', { count: totalLayers })}
                </div>
            </div>
            {item.baseKey && onClickDeleteItem && (
                <div className="template-designer__filter-list-item__delete-button">
                    <IconButton color="default" onClick={() => onClickDeleteItem(item)} size="small">
                        <IconDelete />
                    </IconButton>
                </div>
            )}
        </ListItemButton>
    );
};

export default FilterListItem;
