import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { TemplateType } from 'types/templates.type';
import Translation from 'components/data/Translation';
import SearchField from 'components/ui-components/SearchField';
import Composition from 'components/template-designer/types/composition.type';
import Page from 'components/template-designer/types/page.type';
import { AdobeLayer } from 'components/template-designer/types/layer.type';
import Attribute from 'components/template-designer/types/attribute.type';
import Button from 'components/ui-components-v2/Button';
import SelectLayer from './select-layer';
import { Selection } from './connect-layer-dialog';
import EmptyState from '../../ui-components/empty-state';
import '../styles/select-layers.scss';

interface Props {
    className?: string;
    templateType: TemplateType;
    items: Composition[] | Page[];
    attribute: Attribute;
    layers: { [key: string]: AdobeLayer[] };
    allLayers: { item: string; layer: AdobeLayer }[];
    selectedItem: Composition | Page | null;
    selection: Selection[];
    alreadySelected: Selection[];
    handleSelection: (selection: { items: Composition[] | Page[]; layer: AdobeLayer }[]) => void;
}

/**
 * @param className - Classname for styling.
 * @param templatetype - Type of the template.
 * @param items - Items from parseAdobe function.
 * @param attribute - Attribute that is selected.
 * @param layers - Layers that can be changed with the current attribute.
 * @param allLayers - Same layers in an array filtered by selected compositions.
 * @param selectedItem - The composition that is filtered on.
 * @param selection - Current selection of all layers and compositions.
 * @param alreadySelected - Inputs that are already added to the interface setup.
 * @param handleSelection - Function to call for when selecting a layer.
 * @returns List of the layers that can be changed with selected attribute. Search field to filter.
 */
const SelectLayers = ({ className, templateType, items, attribute, layers, allLayers, selectedItem, selection, alreadySelected, handleSelection }: Props) => {
    const [searchTerm, setSearchTerm] = useState<string>('');

    /**
     * Filter layers by the search term.
     */
    const filteredLayers = useMemo(() => {
        if (!searchTerm) {
            return allLayers;
        }

        return allLayers.filter((layer) => {
            const searchParams = (layer.layer.key + layer.layer.title + layer.layer.type).toLowerCase();
            return searchParams.includes(searchTerm.toLowerCase());
        });
    }, [allLayers, searchTerm]);

    /**
     * Check if there are layers by checking every item.
     */
    const layersFound = useMemo(() => Object.keys(filteredLayers).every((item) => filteredLayers[item].length === 0), [filteredLayers]);

    /**
     * Get all compositions or pages where the current layer is in.
     * @param layer - Current layer.
     * @returns All compositions or pages where the current layer is in.
     */
    const getLayerItems = useCallback(
        (layer: { item: string; layer: AdobeLayer }) => {
            if (!selectedItem) {
                const foundItems: Composition[] | Page[] = [];

                items.forEach((item) => {
                    const found =
                        templateType === 'dynamicAfterEffects'
                            ? layers[item.key].find(
                                  (item) =>
                                      item.key === layer.layer.key ||
                                      (item.originalName && layer.layer.originalName && item.originalName === layer.layer.originalName)
                              )
                            : layers[item.key].find((item) => item.title === layer.layer.title);
                    if (found) {
                        foundItems.push(item);
                    }
                });

                return foundItems;
            }

            const found = items.find((item) => item.key === layer.item);

            if (!found) {
                return [];
            }

            return [found];
        },
        [selectedItem, items, layers]
    );

    /**
     * Get a new selection based on the current filteredLayers.
     * Only add unselected layers by filtering layers that are already selected.
     */
    const handleSelectAll = useCallback(() => {
        const newSelection = filteredLayers
            .map((layer) => {
                const layerItems = getLayerItems(layer);

                return {
                    layer: layer.layer,
                    items: layerItems
                };
            }, [])
            /**
             * Filter out the layers that are in the selection.
             */
            .filter((item) => !selection.map((oldSelection) => oldSelection.layer).includes(item.layer.key))
            /**
             * Filter out the layers that are already selected.
             */
            .filter((item) => !alreadySelected.map((oldSelection) => oldSelection.layer).includes(item.layer.key));

        handleSelection(newSelection);
    }, [filteredLayers, selection]);

    return (
        <div className={classNames('template-designer__select-layers', className)}>
            <div className="template-designer__select-layers__header">
                <div className="template-designer__select-layers__title-container">
                    <h4 className="template-designer__select-layers__title">
                        {Translation.get('adobe.general.connectLayerDialog.selectLayers', 'template-designer')}
                    </h4>
                    <Button className="template-designer__select-layers__select-all" variant="text" onClick={handleSelectAll}>
                        {Translation.get('labels.selectAll', 'common')}
                    </Button>
                </div>
                <SearchField
                    className="template-designer__select-layers__search"
                    onChange={(_: string, search: string) => setSearchTerm(search)}
                    wait={false}
                />
            </div>

            <div className="template-designer__select-layers__content">
                <div className="template-designer__select-layers__content__list">
                    {layersFound && (
                        <EmptyState
                            className="template-designer__select-layers__content__list__empty"
                            headline={Translation.get('adobe.general.connectLayerDialog.noLayersTitle', 'template-designer')}
                            subline={
                                searchTerm
                                    ? Translation.get('adobe.general.connectLayerDialog.noSearchResultsDescription', 'template-designer')
                                    : Translation.get('adobe.general.connectLayerDialog.noLayersTitle', 'template-designer')
                            }
                        />
                    )}

                    {filteredLayers.map((layer) => {
                        const layerItems = getLayerItems(layer);

                        return (
                            <SelectLayer
                                key={layer.item + '_' + layer.layer.key}
                                templateType={templateType}
                                attribute={attribute}
                                layer={layer.layer}
                                layerItems={layerItems}
                                selection={selection}
                                alreadySelected={alreadySelected}
                                handleSelection={handleSelection}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default SelectLayers;
